<template>
  <div class="card">
    <div class="bg-blue-light px-1 rounded pb-2">
      <TitleButton
          class="mt-1"
          btnTitle="List"
          :showBtn="true"
          :showAddNew="false"
          title="New GRN"
          @onClickAddNewButton="navigateToListPage"
      />

      <div class="row mt-2 gy-1">
        <div class="row mt-1">
          <div class="col-sm-6">
            <label class="align-middle">Supplier</label>
            <v-select
                placeholder="Select Supplier type"
                v-model="formData.supplier_id"
                :options="suppliers"
                label="supplier"
                :reduce="supplier => supplier.id"
            />
          </div>
          <div class="col-sm-3">
            <label class="align-middle">GRN Date</label>
            <input type="date" class="form-control" v-model="formData.grn_date">
          </div>
          <div class="col-sm-3">
            <label class="align-middle">GRN No</label>
            <input type="text" class="form-control" v-model="formData.grn_no">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-sm-6">
            <label class="align-middle">Warehouse</label>
            <v-select
                placeholder="Select Warehouse"
                v-model="formData.warehouse_id"
                :options="warehouses"
                label="warehouse"
                :reduce="warehouse => warehouse.id"
            />
          </div>
          <div class="col-sm-3">
            <label class="align-middle">PO Ref#</label>
            <input type="text" class="form-control" v-model="formData.po_ref">
          </div>
          <div class="col-sm-3">
            <label class="align-middle">PO Date</label>
            <input type="date" class="form-control" v-model="formData.po_date">
          </div>
        </div>
      </div>
    </div>

    <div class="px-2 mt-2">
      <AddItemDetails
          class="mb-2"
          :products="products"
          v-for="(item, index) in formData.item_details"
          :key="index"
          :index="index"
          :item="item"
          @onClose="onClose"
      />
    </div>
    <div class="row me-1 mt-3 px-2">
      <div class="col-12 col-sm-4 col-lg-8 mb-1 mb-sm-0">
        <AddButton title="Add line" @onClickAdd="onClickAdd"/>
      </div>
    </div>

    <div class="p-1">
      <div class="row mt-3 px-2">
        <div class="col-12">
          <div class="mb-2">
            <label for="note" class="form-label fw-bold">Memo</label>
            <textarea v-model="formData.memo" placeholder="Memo" class="form-control" rows="2" id="memo"></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2">
      <hr class="hr-full-width mb-1">
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true)"
              class="btn btn-primary">
        Save
      </button>
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(false)"
              class="btn btn-primary">Save & New
      </button>
      <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
    </div>

    <GlobalLoader/>
  </div>
</template>
<script setup>
import {inject, ref} from 'vue'
import {useRoute, useRouter} from "vue-router";
import TitleButton from '@/components/atom/TitleButton'
import AddItemDetails from "@/components/molecule/procurement/inventory/grn/AddItemDetails";
import AddButton from "@/components/atom/AddButton";

const router = useRouter()
const route = useRoute()
const showError = inject('showError');
const showSuccess = inject('showSuccess')

let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let suppliers = ref([])
let warehouses = ref([])
let products = ref([])
let formData = ref({
  supplier_id: null,
  grn_date: null,
  grn_no: null,
  warehouse_id: null,
  po_ref: null,
  po_date: null,
  memo: null,
  item_details: [
    {
      product_id: null,
      receive_qty: null,
      note: null
    }
  ],
})

function navigateToListPage() {
  router.push({name: `grn-list`, params: route.params, query: route.query});
}

function handleSubmit() {

}

function onClose(index) {
  formData.value.item_details.splice(index, 1)
}

function onClickAdd() {
  formData.value.item_details.push({
    product_id: null,
    receive_qty: null,
    note: null
  })
}
</script>